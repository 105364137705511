/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Custom components
import TempDataChart from "myComponents/tempDataChart/TemperatureChart";
import EnergySavingsChart from "myComponents/tempDataChart/EnergySavingsChart";
import DustChart from "myComponents/tempDataChart/DustChart";

import axios from "axios";

import { useState, useEffect } from "react";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
}

function Dashboard() {
  const [totalDataPoints, setTotalDataPoints] = useState(0);
  const [avgTemperature, setAvgTemperature] = useState(0);
  const [humidity, setHumidity] = useState(0);
  const [dust, setDust] = useState(0);

  // API calls
  useEffect(() => {
    // get all data points
    axios.get(baseUrl + "data/getAll").then((res) => {
      setTotalDataPoints(res.data.length);
    });

    // get avg temperature
    axios.get(baseUrl + "data/sensorData?sensorName=t&latestArray=100&isAvg=true").then((res) => {
      setAvgTemperature(res.data);
    });

    // get humidity
    axios.get(baseUrl + "data/sensorData?sensorName=H&isLatest=true").then((res) => {
      setHumidity(res.data);
    });

    // get dust
    axios.get(baseUrl + "data/sensorData?sensorName=c&isLatest=true").then((res) => {
      setDust(res.data);
    });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <DefaultInfoCard
                icon="leaderboard"
                title="Total Data Points"
                color="secondary"
                value={kFormatter(totalDataPoints)}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <DefaultInfoCard
                icon="thermostat"
                title="Average Temperature"
                color="success"
                value={Math.round(avgTemperature).toString() + "°C"}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <DefaultInfoCard
                icon="invert_colors"
                title="Humidity"
                color="info"
                value={humidity.toString()}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <DefaultInfoCard icon="grain" title="Dust" color="primary" value={dust.toString()} />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <EnergySavingsChart />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TempDataChart />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DustChart />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
