import { useState, useEffect } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import TempDataChart from "myComponents/tempDataChart/TemperatureChart";
import EnergySavingsChart from "myComponents/tempDataChart/EnergySavingsChart";
import DustChart from "myComponents/tempDataChart/DustChart";
import MDBadge from "components/MDBadge"; // import the MDBadge component
import Switch from "@mui/material/Switch"; // import the Switch component
import FormControlLabel from "@mui/material/FormControlLabel"; // import the FormControlLabel component
import Card from "@mui/material/Card"; // import the Card component

const baseUrl = process.env.REACT_APP_BASE_API_URL;

function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
}

function DeviceDashboard({ deviceName }) {
  const [totalDataPoints, setTotalDataPoints] = useState(0);
  const [avgTemperature, setAvgTemperature] = useState(0);
  const [humidity, setHumidity] = useState(0);
  const [dust, setDust] = useState(0);
  console.log(">>>>>> Device Dashboard: ", deviceName);

  // API calls
  useEffect(() => {
    const urlParams = `?deviceName=${deviceName}`; // add deviceName as query parameter

    // get all data points
    axios.get(baseUrl + "data/getAll" + urlParams).then((res) => {
      setTotalDataPoints(res.data.length);
    });

    // get avg temperature
    axios
      .get(baseUrl + "data/sensorData" + urlParams + "&sensorName=t&latestArray=100&isAvg=true")
      .then((res) => {
        setAvgTemperature(res.data);
      });

    // get humidity
    axios
      .get(baseUrl + "data/sensorData" + urlParams + "&sensorName=H&isLatest=true")
      .then((res) => {
        setHumidity(res.data);
      });

    // get dust
    axios
      .get(baseUrl + "data/sensorData" + urlParams + "&sensorName=c&isLatest=true")
      .then((res) => {
        setDust(res.data);
      });
  }, [deviceName]); // dependency array

  // --------------------------------------------------
  // Add state for device status and toggle
  const [deviceStatus, setDeviceStatus] = useState(null);
  const [toggleStatus, setToggleStatus] = useState(null);

  // Add useEffect to get device status
  useEffect(() => {
    const getStatus = async () => {
      const _status = await axios.get(
        baseUrl + "data/deviceStatus?deviceName=" + deviceName + "&isLatest=true"
      );

      let _toggleState = -1;
      if (_status.data === "FAULT") {
        setDeviceStatus(<MDBadge badgeContent="FAULT" container color="error" />);
        _toggleState = -1;
      } else if (_status.data === "OFF" || _status.data === 0) {
        setDeviceStatus(<MDBadge badgeContent="OFF" container color="warning" />);
        _toggleState = 0;
      } else if (_status.data === "DIM") {
        setDeviceStatus(<MDBadge badgeContent="DIM" container color="info" />);
        _toggleState = 1;
      } else if (_status.data === "FULL INTENSITY") {
        setDeviceStatus(<MDBadge badgeContent="FULL INTENSITY" container color="success" />);
        _toggleState = 1;
      } else if (_status.data === 1) {
        setDeviceStatus(<MDBadge badgeContent="ON" container color="success" />);
        _toggleState = 1;
      } else {
        setDeviceStatus(<MDBadge badgeContent="UNKNOWN" container color="dark" />);
        _toggleState = -1;
      }
      setToggleStatus(_toggleState);
    };

    getStatus();
  }, [deviceName]);

  // Add handleToggleChange function to update toggleStatus
  const handleToggleChange = async (event) => {
    if (event.target.checked) {
      await axios.get(baseUrl + "device/turnOn?deviceName=" + deviceName);
    } else {
      await axios.get(baseUrl + "device/turnOff?deviceName=" + deviceName);
    }
    setToggleStatus(event.target.checked ? 1 : 0);
  };

  return (
    <MDBox py={3}>
      <Grid container spacing={1} justify="center">
        <Grid item xs={12} md={6} lg={4.5} />
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={3}>
            <DefaultInfoCard
              title="Device Control"
              color={toggleStatus === 1 ? "success" : toggleStatus === 0 ? "warning" : "error"}
              value={
                <Grid container direction="column" alignItems="center" justify="center">
                  <Grid item>{deviceStatus}</Grid>
                  <Grid item>
                    {toggleStatus !== -1 && (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={toggleStatus === 1}
                            onChange={handleToggleChange}
                            inputProps={{ "aria-label": "Toggle device" }}
                            color={
                              toggleStatus === 1
                                ? "success"
                                : toggleStatus === 0
                                ? "warning"
                                : "error"
                            }
                          />
                        }
                        label={""}
                        labelPlacement="top"
                      />
                    )}
                  </Grid>
                </Grid>
              }
            />
          </MDBox>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={1.5} />
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DefaultInfoCard
              icon="thermostat"
              title="Average Temperature"
              color="success"
              value={Math.round(avgTemperature).toString() + "°C"}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DefaultInfoCard
              icon="invert_colors"
              title="Humidity"
              color="info"
              value={humidity.toString()}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DefaultInfoCard icon="grain" title="Dust" color="primary" value={dust.toString()} />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={1.5} />
      </Grid>

      <MDBox mt={4.5}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5} mx={1.5}>
              <EnergySavingsChart deviceName={deviceName} />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5} mx={1.5}>
              <TempDataChart deviceName={deviceName} />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5} mx={1.5}>
              <DustChart deviceName={deviceName} />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default DeviceDashboard;
