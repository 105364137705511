import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import MDBox from "components/MDBox";
import { formatDistanceToNow, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

function EnergySavingsChart({ deviceName }) {
  const [getEnergySavingsData, setGetEnergySavingsData] = useState({});
  const [lastUpdated, setLastUpdated] = useState("");

  const url = deviceName
    ? baseUrl + "data/sensorData?sensorName=p1&latestArray=15&deviceName=" + deviceName
    : baseUrl + "data/sensorData?sensorName=p1&latestArray=15";

  useEffect(() => {
    console.log("Energy Savings Chart: ", url);
    console.log("Energy Savings Chart Device: ", deviceName);
    axios.get(url).then((res) => {
      const tempData = {
        labels: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
        datasets: {
          label: "Energy Savings",
          data: res.data,
        },
      };
      setGetEnergySavingsData(tempData);
      axios.get(baseUrl + "data/lastUpdated?deviceName=" + deviceName).then((res) => {
        setLastUpdated(formatDistanceToNow(parseISO(res.data), { addSuffix: true }));
      });
    });
  }, [deviceName, url]);

  return (
    <div>
      <MDBox mb={3}>
        <ReportsLineChart
          color="success"
          title="Energy Savings"
          // description="Chart"
          date={lastUpdated}
          chart={getEnergySavingsData}
        />
      </MDBox>
    </div>
  );
}

export default EnergySavingsChart;
