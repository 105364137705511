import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DeviceDashboard from "./DeviceDashboard"; // Import your DeviceDashboard component
import { useParams } from "react-router-dom";

function DeviceDashboardIndex() {
  const { deviceName } = useParams();
  console.log("deviceName: ", deviceName);

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Device Dashboard - {deviceName}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DeviceDashboard deviceName={deviceName} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default DeviceDashboardIndex;
