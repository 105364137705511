import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import MDBox from "components/MDBox";
import axios from "axios";
import { formatDistanceToNow, parseISO } from "date-fns";
import { useEffect, useState } from "react";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

function TempDataChart({ deviceName }) {
  const [temperatureData, setTemperatureData] = useState({});
  const [lastUpdated, setLastUpdated] = useState("");

  const url = deviceName
    ? baseUrl + "data/sensorData?sensorName=t&latestArray=15&deviceName=" + deviceName
    : baseUrl + "data/sensorData?sensorName=t&latestArray=15";

  useEffect(() => {
    axios.get(url).then((res) => {
      console.log("Temperature Data: ", res);
      const tempData = {
        labels: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
        datasets: {
          label: "Temperature",
          data: res.data,
        },
      };
      setTemperatureData(tempData);
      axios.get(baseUrl + "data/lastUpdated?deviceName=" + deviceName).then((res) => {
        setLastUpdated(formatDistanceToNow(parseISO(res.data), { addSuffix: true }));
      });
    });
  }, [deviceName, url]);

  return (
    <div>
      <MDBox mb={3}>
        <ReportsLineChart
          color="info"
          title="Temperature"
          // description="Temperature Chart"
          date={lastUpdated}
          chart={temperatureData}
        />
      </MDBox>
    </div>
  );
}

export default TempDataChart;
