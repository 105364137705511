import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";

import { useEffect, useState } from "react";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

async function getTableRow(device) {
  var status, toggle, temperature, humidity, dust, view;
  const _status = await axios.get(
    baseUrl + "data/deviceStatus?deviceName=" + device + "&isLatest=true"
  );
  const _temp = await axios.get(
    baseUrl + "data/sensorData?sensorName=t&isLatest=true&deviceName=" + device
  );
  const _humidity = await axios.get(
    baseUrl + "data/sensorData?sensorName=H&isLatest=true&deviceName=" + device
  );
  const _dust = await axios.get(
    baseUrl + "data/sensorData?sensorName=c&isLatest=true&deviceName=" + device
  );

  let _toggleState = -1;
  if (_status.data === "FAULT") {
    status = <MDBadge badgeContent="FAULT" container color="error" />;
    _toggleState = -1;
  } else if (_status.data === "OFF" || _status.data === 0) {
    status = <MDBadge badgeContent="OFF" container color="warning" />;
    _toggleState = 0;
  } else if (_status.data === "DIM") {
    status = <MDBadge badgeContent="DIM" container color="info" />;
    _toggleState = 1;
  } else if (_status.data === "FULL INTENSITY") {
    status = <MDBadge badgeContent="FULL INTENSITY" container color="success" />;
    _toggleState = 1;
  } else if (_status.data === 1) {
    status = <MDBadge badgeContent="ON" container color="success" />;
    _toggleState = 1;
  } else {
    status = <MDBadge badgeContent="UNKNOWN" container color="dark" />;
    _toggleState = -1;
  }

  if (_toggleState === -1) {
    toggle = <Switch disabled />;
  } else if (_toggleState === 0) {
    toggle = (
      <Switch
        onChange={async (state) => {
          if (state.target.checked) {
            await axios.get(baseUrl + "device/turnOn?deviceName=" + device);
          } else {
            await axios.get(baseUrl + "device/turnOff?deviceName=" + device);
          }
        }}
      />
    );
  } else if (_toggleState === 1) {
    toggle = (
      <Switch
        defaultChecked
        onChange={async (state) => {
          if (state.target.checked) {
            await axios.get(baseUrl + "device/turnOn?deviceName=" + device);
          } else {
            await axios.get(baseUrl + "device/turnOff?deviceName=" + device);
          }
        }}
      />
    );
  }

  // status = _status.data;
  temperature = _temp.data;
  humidity = _humidity.data;
  dust = _dust.data;
  view = (
    <MDButton color="info" size="small" component={Link} to={`/device-dashboard/${device}`}>
      View
    </MDButton>
  );

  console.log("status: ", status);
  console.log("temperature: ", temperature);
  console.log("humidity: ", humidity);
  console.log("dust: ", dust);
  return {
    deviceName: device,
    status,
    toggle,
    temperature,
    humidity,
    dust,
    view,
  };
}

function DeviceListTable() {
  const [columns, setColumns] = useState([
    // add a view button
    { Header: "Device Name", accessor: "deviceName", width: "45%", align: "left" },
    { Header: "Status", accessor: "status", align: "center" },
    { Header: "On/Off", accessor: "toggle", align: "center" },
    { Header: "Temperature", accessor: "temperature", align: "center" },
    { Header: "Humidity", accessor: "humidity", align: "center" },
    { Header: "Dust", accessor: "dust", align: "center" },
    { Header: "", accessor: "view", align: "center" },
  ]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    console.log("useEffect");
    console.log("baseUrl: ", baseUrl);
    console.log("process.env: ", process.env);
    axios
      .get(baseUrl + "device/listAll")
      .then((res) => {
        const deviceNames = res.data.map((device) => device.deviceName);
        deviceNames.forEach((device) => {
          getTableRow(device)
            .then((row) => {
              setRows((prevRows) => [...prevRows, row]);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <MDBox mb={3}>
        {rows.length > 0 &&
          (console.log("rows: ", rows),
          (
            <DataTable
              table={{ columns, rows }}
              showTotalEntries={false}
              isSorted={false}
              noEndBorder
              entriesPerPage={false}
            />
          ))}
      </MDBox>
    </div>
  );
}

export default DeviceListTable;
