import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import MDBox from "components/MDBox";
import axios from "axios";
import { formatDistanceToNow, parseISO } from "date-fns";

import { useEffect, useState } from "react";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

function DustChart({ deviceName }) {
  const [dustData, setDustData] = useState({});
  const [lastUpdated, setLastUpdated] = useState("");

  const url = deviceName
    ? baseUrl + "data/sensorData?sensorName=c&latestArray=15&deviceName=" + deviceName
    : baseUrl + "data/sensorData?sensorName=c&latestArray=15";

  useEffect(() => {
    axios.get(url).then((res) => {
      const tempData = {
        labels: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
        datasets: {
          label: "Dust",
          data: res.data,
        },
      };
      setDustData(tempData);
      axios.get(baseUrl + "data/lastUpdated?deviceName=" + deviceName).then((res) => {
        setLastUpdated(formatDistanceToNow(parseISO(res.data), { addSuffix: true }));
      });
    });
  }, [deviceName, url]);

  return (
    <div>
      <MDBox mb={3}>
        <ReportsLineChart
          color="primary"
          title="Dust"
          // description="Chart"
          date={lastUpdated}
          chart={dustData}
        />
      </MDBox>
    </div>
  );
}

export default DustChart;
